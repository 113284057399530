<template>
	<v-container fluid>
		<v-row align="center">
			<v-col class="d-flex" cols="12" sm="12" md="12">
				{{ authorName }}
			</v-col>
			<v-col v-if="false" cols="12" sm="12" md="12">
				<v-dialog
					ref="refDate"
					v-model="modal1"
					:return-value.sync="date"
					persistent
					width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="reservationDate"
							clearable
							label="노출 예약일을 설정해주세요"
							readonly
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="date"
						:min="dateMin"
						:max="dateMax"
						scrollable
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="modal1 = false">
							Cancel
						</v-btn>
						<v-btn text color="primary" @click="calendarNext(date)">
							OK
						</v-btn>
					</v-date-picker>
				</v-dialog>
				<v-dialog
					ref="refTime"
					v-model="modal2"
					:return-value.sync="time"
					persistent
					width="290px"
				>
					<v-time-picker v-if="modal2" v-model="time" full-width>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="modal2 = false">
							Cancel
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="calendarDateSet(time)"
						>
							OK
						</v-btn>
					</v-time-picker>
				</v-dialog>
			</v-col>
			<v-col cols="12" sm="12" md="12">
				<v-textarea
					label="내용"
					v-model="message"
					auto-grow
					outlined
					counter
				></v-textarea>
			</v-col>
			<v-col>
				<v-text-field
					v-model="youtube"
					label="Youtube"
					outlined
					dense
					hint="유튜브 공유 주소를 복사 붙여넣기 해주세요."
				></v-text-field>
			</v-col>
			<v-col cols="12" sm="12" md="12">
				등록된 이미지
				<v-row>
					<v-col cols="12">
						<draggable v-model="images">
							<transition-group
								tag="div"
								class="grid"
								name="grid"
							>
								<div
									class="cell d-flex child-flex"
									v-for="(image, index) in this.images"
									:key="index"
								>
									<v-badge
										avatar
										bordered
										overlap
										@click.native="deleteImage(index)"
										><template v-slot:badge>
											<v-avatar>
												<v-img
													src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAB9CAMAAAC4XpwXAAAAYFBMVEX///8AAAD8/Pzf39/Z2dk+Pj4nJyf29vbIyMgaGhouLi7q6uqGhoZpaWlvb2/AwMBXV1fw8PC0tLRISEiSkpIPDw+fn592dnZDQ0NfX1/S0tIfHx+6urqmpqY2NjZ/f3/D6UaCAAAEs0lEQVRogcVb24KqMAzEAoIiFxEP6ir+/18eEV0hmZYCdTOPCp3SpLk19bzpiIK8rOpzuD6uVqvjOjzXVZkH0YyRJhOXdbNZIWyauvzmFJI8CyHxB2GWJ9+gVtf0Z4T6tQZpqRxz+zsr5jd2gUPu62kSd4vT1Q21uq4nc7dYXx0IID/M4m5xyBdy++fZ3C3O/hLyahF3i2o2d4zNyjRs4lncatom02M3Q/uSMbNmj3Cy+YuPzsgfnmji6v9zyN3i3xTyzGLAsEjvu2p3TwsbS5hZc6tihDer4n300SUV7eMqG5lDYal7kYl8U9z8PX7NvxUmL3i28v0m8qY0Gy+/NOyUwoLesOzZxWLygV5nzuOLryXf4QXn2GvNVDH2qm7mmS33k187ivk9zT4/2Kx5HxeNXzbu+1jzzvRQVWm+w2D1Emhej/OctI8H09p8BbdLOjtAStFwoW44qKuTLDQBXP0dfhYKvVxA7nklGhKLHkUy8wKTD9AXbdCDKIZbSo7pQawXfIUc0/NNBEJnN+kIkP2ZPpPzZ5Zoex9A80maobhlTB2Ro31/GG76K3vg6C4NVtzqDYXKE8VFORCBz0Zf9//mn+5K6B246PuSZxHhwW35IWJqdfj8yVdG48+VMs9K+//FIFnmXnAMEuxOTVjrk/K8DpuTpmjCop1fZ6PYxFAYFb03jiYt+038UhSLJIzjvUpM55ATjD66sUafF3y2zQnRs/V9bzpmDdCn9x/acvpg2/sfGao9JXk9lFDXiqQ+dEJrag38ocFAi0Ml/9MJkJl4pPB04Yayp1JFomNqn8NJheBVxSxC/+vZjj2hjUejxucSR/RXFEyphhL0VC9gdrpB7NTVhq1y0rBigyw8CHd/VW+gcK+BEbtP9SsAU8LJVs0Y3qrng1JmDcegKWIJxr3BN0H00akeNyMrFj28cANzpBLVuFYm+O5Z7iJWWG89rpzNQ+mINEJNzpagWvH2ymX+EIkmW6L6vYmY0mmTXKBcGMASvkD3dsAEqi+s8o0FAb1AB5oyxEzlDTE8lDGDISSjsX3J5mOqUUD9JjBVRamnqdiGMxYK0N4egLmfASLydE1zGG163WFE9fQK9wS1mGdq+8fKSkbVMyhcB2LtQhrJj2YwJtUbzQFIHLP2SJ5xHxvAoHrjZfj78IWjR0bQ1DUGX49lv7XIfmiMQtltTnF45tPCJt+m21v422XlLqvzsvtd1tbJ2nlZHyfr32VjG9m4Tjam/UY8f8JDgHheOJeRzeNkc9g/y9/pwnf6LVq7EK7bJPTU/E9rVrL1OuFa5cQ6beO2Titboxauz4OzCbfNofxsou+I/v5cZpD5iJ5JCZ/HyZ5Fyp7DwjPoZX0Hb9icQcMA7e/O32V7D4T7LoR7TmT7bVz3GqlpvUayfVYue8yi6T1mwv11MGN6IpvSjTy3t1C4r9JT+n791Gb9L4t6Ss39tLeRftrbwn7aB73ptoK5l9jU7m/XSyzcR+3pNX8wh0k95LieoIFo/7zw3QHhexPCd0Y82fsyLSTvCnmL70mlS/MxyTtinvD9uBYzvt/V3cAnJO9FtrC+E/qTulryIQTvw3YQvAv8mULM7kHHs4j/A7LHSIBx0YCpAAAAAElFTkSuQmCC"
												></v-img>
											</v-avatar>
										</template>
										<v-img
											id="image"
											aspect-ratio="1"
											:src="image"
										/>
									</v-badge>
								</div>
							</transition-group>
						</draggable>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-file-input
					v-model="files"
					:rules="rules"
					small-chips
					counter
					show-size
					multiple
					:label="
						files.length
							? '첨부파일목록'
							: '이미지는 최대 10장(총 2MB)까지 등록가능합니다.'
					"
					accept="image/png, image/jpeg, image/bmp"
				></v-file-input>
			</v-col>
			<v-col
				v-if="youtube || images.length || files.length || linkUrl"
				cols="12"
				sm="12"
				md="12"
			>
				<v-text-field
					v-model="linkUrl"
					label="이미지/영상 영역 링크주소"
					outlined
					dense
					hint="링크 주소를 입력 해주세요."
				></v-text-field>
			</v-col>
		</v-row>
		<v-btn
			class="ma-2"
			:loading="loading"
			:disabled="loading"
			color="info"
			@click="write"
		>
			수정하기
			<template v-slot:loader>
				<span class="custom-loader">
					<v-icon light>mdi-cached</v-icon>
				</span>
			</template>
		</v-btn>
	</v-container>
</template>

<script>
import { editArticle } from "@/api/article";
import draggable from "vuedraggable";

export default {
	components: {
		draggable,
	},
	name: "ArticleEdit",
	data: () => ({
		authorNames: {
			2: "쇼미더트렌드",
			3: "최은경의 W",
			4: "더 컬렉션",
			19: "똑.소.리",
		},
		author: "",
		reservationDate: null,
		date: new Date().toISOString().substr(0, 10),
		dateMin: new Date().toISOString().substr(0, 10),
		dateMax: new Date(Date.now() + 1000 * 60 * 60 * 24 * 60)
			.toISOString()
			.substr(0, 10),
		time: null,
		modal1: false,
		modal2: false,
		message: "",
		youtube: "",
		images: [],
		files: [],
		linkUrl: "",
		rules: [
			(value) => {
				if (value) {
					let filesize = 0;
					value.forEach((d) => {
						filesize += d.size;
					});
					return (
						!value ||
						filesize < 2000000 ||
						"업로드된 이미지의 용량은 총 2MB를 초과할 수 없습니다."
					);
				}
				return true;
			},
		],
		loader: null,
		loading: false,
	}),
	computed: {
		article() {
			return this.$store.state.article[this.$route.params.articleSk];
		},
		authorName() {
			return this.authorNames[this.article.author];
		},
	},
	methods: {
		calendarNext(date) {
			this.$refs.refDate.save(date);
			this.modal1 = false;
			this.modal2 = true;
		},
		calendarDateSet(time) {
			if (!time) {
				this.modal2 = false;
				return;
			}
			this.$refs.refTime.save(time);
			this.reservationDate = this.date + " " + this.time;
		},
		setYTid(url) {
			var regExp =
				/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/;
			var match = url.match(regExp);
			return match && match[7].length == 11 ? match[7] : "";
		},
		deleteImage(index) {
			if (confirm("선택하신 이미지를 삭제하시겠습니까?")) {
				this.images.splice(index, 1);
			}
		},
		async write() {
			if (!this.message) {
				this.snackbar(
					"commonSnackbar",
					"글은 반드시 등록해주셔야 합니다."
				);
				return;
			}
			this.loading = true;
			let result = await editArticle({
				pk: "post#" + this.article.author,
				sk: this.article.sk,
				// reservationDate: this.reservationDate
				// 	? this.reservationDate
				// 	: "",
				message: this.message,
				youtube: this.setYTid(this.youtube),
				images: this.images,
				files: this.files,
				linkUrl: this.linkUrl,
			});
			this.loading = false;
			let editedArticle =
				this.$store.state.article[this.$route.params.articleSk];
			editedArticle.message = this.message;
			editedArticle.youtube = this.setYTid(this.youtube);
			// 이미지는 신규등록 이미지의 경우 서버에서 데이터를 가져아야 함
			editedArticle.images = this.images;
			editedArticle.linkUrl = this.linkUrl;
			if (result == true) {
				this.snackbar("commonSnackbar", "수정이 완료되었습니다.");
				this.$router.go(-1);
			} else {
				this.snackbar(
					"commonSnackbar",
					"에러가 발생하였습니다. 다시 시도해주세요."
				);
			}
		},
	},
	mounted() {
		this.message = this.article.message;
		if (this.article.youtube) {
			this.youtube = "https://youtu.be/" + this.article.youtube;
		}
		if (this.article.images && this.article.images[0]) {
			this.images = this.article.images;
		}
		if (this.article.linkUrl) {
			this.linkUrl = this.article.linkUrl;
		}
	},
};
</script>

<style>
.custom-loader {
	animation: loader 1s infinite;
	display: flex;
}
@-moz-keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
@-webkit-keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
@-o-keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 0.6em;
}

.cell {
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>